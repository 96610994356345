import React from 'react';

import Layout from '../components/layout';
import withBg from '../helpers/withBg';
import SEO from '../components/seo';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal/index';
import SidebarStaticPage from '../components/Sidebar/SidebarStaticPage';

const data = {
  title: 'Privacy Policy',
  seoTitle: 'Privacy Policy | Travelopod',
  seoDescription:
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.'
};

class PrivacyPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber
    };
  }

  render() {
    const { phoneNumber } = this.state;
    const description = data.seoDescription;
    const seotitle = data.seoTitle;

    return (
      <Layout
        isStaticPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        <SEO title={seotitle} description={description} />
        <div className="main c-content-page">
          <div className="page-content">
            <div className="flight-content">
              <div className="text-block">
                <h1>Privacy Policy</h1>
                <h3>Information Collection</h3>
                <p>
                  When you share your business’s data, we want you to know it is
                  protected. Travelopod, Inc. ("Travelopod"), the owner of
                  www.Travelopod.com (the "Site"), are committed to protecting
                  your privacy. Your Personal Information (ie. data that
                  identifies you or from which you are identifiable) is
                  collected for various purposes, including, but not limited to:
                  responding to inquiries, processing applications, billing,
                  communicating with you ("you, the traveler, or the "user"),
                  submissions to our referral program, and submissions to our
                  travel blog. We collect information from you when you place an
                  order or subscribe to our newsletter.
                </p>
                <p>
                  We are committed to complying with applicable data protection
                  laws. We offer personalized shopping experiences and
                  advertising through Intent Media, which uses online
                  identifiers to identify visitors. To view Intent Media’s
                  privacy policy,{' '}
                  <a href="http://intentmedia.com/privacy-policy/">
                    click here
                  </a>
                  . To opt out,{' '}
                  <a href="http://intentmedia.com/opt-out/">click here</a>.{' '}
                  <br /> Information Use{' '}
                </p>
                <p>
                  Personal Information relayed is used solely to facilitate,
                  improve, and deliver the services we offer. We do not share
                  your Personal Information with outside parties, except where
                  necessary to process a request or inquiry from you, in an
                  emergency situation, to enforce our policies, or where we are
                  permitted to do so by applicable law, such as in response to a
                  request by a law enforcement or governmental authority, in
                  connection with actual or proposed litigation, or to protect
                  our property, people and other rights. Any of the information
                  we collect from you may be used in one of the following ways:
                </p>
                <ul>
                  <li>
                    To personalize your experience (your information helps us to
                    better respond to your individual needs)
                  </li>
                  <li>
                    To improve our website (we continually strive to improve our
                    website offerings based on the information and feedback we
                    receive from you)
                  </li>
                  <li>
                    To improve customer service (your information helps us to
                    more effectively respond to your customer service requests
                    and support needs)
                  </li>
                  <li>
                    To process transactions: Your information, whether public or
                    private, will not be sold, exchanged, transferred, or given
                    to any other company for any reason whatsoever, without your
                    consent, other than for the express purpose of delivering
                    the purchased product or service requested.
                  </li>
                  <li>
                    To send periodic emails: The email address you provide for
                    order processing, may be used to send you information and
                    updates pertaining to your order, in addition to receiving
                    occasional company news, updates, related product or service
                    information, etc.
                  </li>
                  <li>
                    Marketing of Travelopod Services: We may use your data to
                    market our services to you, as an existing client or
                    prospective client entity, including to provide you with
                    promotional materials or offers for products and services
                    from Travelopod (including communications based on your
                    interests, personal and business characteristics, and
                    location). We may provide these communications and offers in
                    various ways, including via email, postal mail, social media
                    platforms, and text or direct messages.
                  </li>
                </ul>
                <p>
                  Note: If at any time you would like to unsubscribe from
                  receiving future emails, we include detailed unsubscribe
                  instructions at the bottom of each email.
                </p>
                <h3>Consent to Processing of Personal Information</h3>
                <p>
                  By providing any Personal Information to Travelopod, all users
                  unambiguously consent to this Privacy Policy and to the
                  transfer of such Personal Information between various states
                  and countries in accordance with Travelopod’S standard
                  operations as necessary to accomplish and carryout the
                  operations of Travelopod. YOUR INFORMATION MAY BE TRANSFERRED
                  TO, AND MAINTAINED ON, COMPUTERS AND SERVERS LOCATED OUTSIDE
                  OF YOUR STATE, PROVINCE, COUNTRY OR OTHER GOVERNMENTAL
                  JURISDICTION WHERE THE PRIVACY LAWS MAY NOT BE AS PROTECTIVE
                  AS THOSE IN YOUR JURISDICTION. IF YOU ARE LOCATED OUTSIDE THE
                  UNITED STATES AND CHOOSE TO PROVIDE INFORMATION TO US,
                  TRAVELOPOD TRANSFERS PERSONAL INFORMATION TO THE UNITED STATES
                  AND PROCESSES IT THERE. YOUR CONSENT TO THIS PRIVACY POLICY
                  FOLLOWED BY YOUR SUBMISSION OF SUCH INFORMATION REPRESENTS
                  YOUR AGREEMENT TO THAT TRANSFER.
                </p>
                <h3>Protecting Your Information</h3>
                <p>
                  We implement a variety of security measures to maintain the
                  safety of your personal information when you place an order or
                  enter, submit, or access your personal information.
                </p>
                <p>
                  We offer the use of a secure server. All supplied
                  sensitive/credit information is transmitted via Secure Socket
                  Layer (SSL) technology and then encrypted into our Payment
                  gateway providers database only to be accessible by those
                  authorized with special access rights to such systems, and are
                  required to?keep the information confidential.
                </p>
                <p>
                  After a transaction, your private information (credit cards,
                  financials, etc.) will not be stored on our servers.
                </p>
                <h3>
                  Non-Personally-Identifiable Information We May Collect Online
                </h3>
                <p>
                  We may use various technologies to collect non-Personal
                  Information about your visits online including: "Cookies" are
                  small pieces of information that are stored by the user's
                  browser on the user's computer's hard drive. Travelopod may
                  use cookies to collect information during and about your
                  visits to our Site, such as the particular site areas you
                  visit. Cookies may also be used to recognize that you have
                  provided Personal Information to us and refrain from
                  requesting the same information a second time (e.g., a
                  username and/or password, or an e-mail address or other
                  contact information). Most browsers are initially set to
                  accept cookies, but can easily be set to refuse cookies.
                  However, that refusal of cookies may limit your ability to
                  take full advantage of all the Site features.
                </p>
                <ol>
                  <li>Cookies.</li>
                  <li>Clear GIFs</li>
                </ol>
                <p>
                  We may use pixel tags (also called clear gifs) or other
                  similar tracking technologies in our websites and/or in our
                  communications to collect information about your navigation on
                  our websites, and to enable us to know whether you have
                  visited a Web page or received a message. This information
                  enables us to serve you and your interests better.
                </p>
                <h3>Do we disclose any information to outside parties?</h3>
                <p>
                  We do not sell, trade, or otherwise transfer to outside
                  parties your personally identifiable information. This does
                  not include trusted third parties who assist us in operating
                  our website, conducting our business, or servicing you, so
                  long as those parties agree to keep this information
                  confidential. We may also release your information when we
                  believe release is appropriate to comply with the law, enforce
                  our site policies, or protect ours or others rights, property,
                  or safety. However, non-personally identifiable visitor
                  information may be provided to other parties for marketing,
                  advertising, or other uses.
                </p>
                <h3>Third-party website links</h3>
                <p>
                  This policy only addresses Travelopod's privacy policy
                  concerning information collected from its own website and does
                  not apply to information that may have been collected by
                  others. This policy does not cover the privacy or information
                  collection practices of third-party websites that may be
                  linked to this website. Please be aware that when you leave
                  this site, you should read the third-party website privacy
                  practices before submitting Personal Information. Travelopod
                  has no control or influence over third-party policies, and
                  cannot be held liable for damage or misdoings of other
                  websites linked or otherwise.
                </p>
                <h3>California Online Privacy Protection Act Compliance</h3>
                <p>
                  Because we value your privacy we have taken the necessary
                  precautions to be in compliance with the California Online
                  Privacy Protection Act. We therefore will not distribute your
                  personal information to outside parties without your consent.
                  As part of the California Online Privacy Protection Act, all
                  users of our site may make any changes to their information at
                  anytime by logging into their account page and editing their
                  information.
                </p>
                <h3>Childrens Online Privacy Protection Act Compliance</h3>
                <p>
                  We are in compliance with the requirements of COPPA (Childrens
                  Online Privacy Protection Act), we do not collect any
                  information from anyone under 13 years of age. Our website,
                  products and services are all directed to people who are at
                  least 13 years old or older.
                </p>
                <h3>Online Privacy Policy Only</h3>
                <p>
                  This online privacy policy applies only to information
                  collected through our website and not to information collected
                  offline. <br /> Your Consent By using our site, you consent to
                  our web site privacy policy.
                </p>
                <h3>Changes to Privacy Policy</h3>
                <p>
                  Any changes to the Privacy Policy will be posted on this page
                  to keep you aware of what information is collected, how it is
                  used, and under what circumstances it will be disclosed. It is
                  your responsibility to review Travelopod' Privacy Policy each
                  time you provide Personal Information to Travelopod as the
                  policy may have changed since the last time you used the Site.
                </p>
              </div>
            </div>
            <SidebarStaticPage phoneNumber={phoneNumber} />
          </div>
        </div>
        <TrustpilotHorizontal />
      </Layout>
    );
  }
}

export default withBg(PrivacyPolicyPage);
